@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-8rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(8rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInUp {
  0% {
    opacity: 0;
    transform: translateY(8rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  scroll-behavior: smooth; }

body {
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
  background: linear-gradient(-45deg, #fff, #c0fefc);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite; }

.heading-primary {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  word-wrap: none;
  transition: all 0.2s; }
  .heading-primary--main {
    display: block;
    font-size: 4.25rem;
    font-weight: 600;
    letter-spacing: 0.5rem;
    animation: moveInLeft 2s ease-in-out; }
    @media only screen and (max-width: 56.25em) {
      .heading-primary--main {
        letter-spacing: 0.1rem; } }
    @media only screen and (max-width: 37.5em) {
      .heading-primary--main {
        letter-spacing: 0;
        font-size: 2.9rem; } }
  .heading-primary--sub {
    display: block;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    animation: moveInRight 2s ease-in-out; }
    @media only screen and (max-width: 56.25em) {
      .heading-primary--sub {
        letter-spacing: 0; } }
    @media only screen and (max-width: 37.5em) {
      .heading-primary--sub {
        font-size: 1.4rem; } }

.heading-secondary {
  font-family: "Montserrat", sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: none;
  hyphens: auto;
  white-space: normal;
  background-image: linear-gradient(to right, #03256c, #03256c);
  background-clip: text;
  color: transparent;
  transition: all 0.2s; }
  .heading-secondary--white {
    color: #fff; }

.heading-tertiary {
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.75rem; }
  .heading-tertiary--color {
    color: #0174b2; }
  .heading-tertiary--success {
    color: #54e346; }
  .heading-tertiary--error {
    color: #e40017; }

.paragraph {
  font-family: "Montserrat", sans-serif;
  color: #393e46;
  margin-bottom: 0; }
  .paragraph--about {
    font-size: 1rem; }
  .paragraph--footer {
    font-size: 1.25rem;
    margin-bottom: 0.75rem; }
    @media only screen and (max-width: 37.5em) {
      .paragraph--footer {
        font-size: 1rem; } }
  .paragraph--trainer-info {
    color: #fff;
    font-family: "Baloo Tammudu 2", cursive; }
    .paragraph--trainer-info--main {
      font-weight: bold; }

.u-margin-bottom-zero {
  margin-bottom: 0; }

.u-margin-bottom-small {
  margin-bottom: 2rem; }

.u-margin-bottom-medium {
  margin-bottom: 4rem; }

.u-margin-bottom-large {
  margin-bottom: 8rem; }

.u-center-text {
  text-align: center; }

.u-text-color {
  color: #03256c;
  font-weight: bold; }

nav {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif; }

.button:link, .button:visited {
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 1rem 3rem;
  font-weight: 400;
  border-radius: 10rem;
  text-transform: uppercase;
  transition: all 0.2s; }

.button-white {
  background-color: #f8f9fa;
  color: #393e46; }
  .button-white:hover {
    color: #393e46; }
  .button-white::after {
    background-color: #f8f9fa; }

.button::after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.4s;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10rem;
  display: inline-block; }

.button-animated {
  animation: moveInUp 1s 0.75s backwards; }

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3); }
  .button:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }

.button:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3); }

.button-text:link, .button-text:visited {
  font-size: 1rem;
  color: #0174b2;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid #0174b2;
  padding-bottom: 3px;
  transition: all 0.2s; }

.button-text:hover {
  background-color: #0174b2;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); }

.button-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0); }

.btn-cards {
  transition: all 0.2s;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 8px;
  border-bottom: 1px solid #03256c;
  border-radius: 3px;
  background-image: linear-gradient(to right, #03256c, #0174b2, #03256c);
  font-weight: 600;
  font-size: 1.25rem; }
  .btn-cards:link, .btn-cards:visited {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent; }
  .btn-cards:hover {
    text-decoration: none;
    background-clip: initial;
    -webkit-background-clip: initial;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); }
  @media only screen and (max-width: 56.25em) {
    .btn-cards:link, .btn-cards:visited {
      text-decoration: none;
      background-clip: initial;
      -webkit-background-clip: initial;
      color: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); } }
  .btn-cards--about {
    background-image: none;
    background-color: #0174b2 !important;
    border-bottom: 1px solid #0174b2; }

.button-last {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8); }

@media (max-width: 768px) {
  .button:link, .button:visited {
    padding: 1rem 3rem; } }

.composition {
  position: relative; }
  .composition__photo {
    width: 55%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    position: absolute;
    z-index: 10;
    transition: all 0.2s;
    outline-offset: 1rem; }
    @media only screen and (max-width: 75em) {
      .composition__photo {
        margin-bottom: 2rem;
        float: left;
        position: relative;
        width: 33.33333333%;
        box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2); }
        .composition__photo:hover {
          outline-offset: 0;
          outline: none !important; } }
    .composition__photo--p1 {
      left: 0.5rem;
      top: 0; }
      @media only screen and (max-width: 75em) {
        .composition__photo--p1 {
          top: 0;
          transform: scale(1.2); } }
    .composition__photo--p2 {
      right: 0;
      top: 2.5rem; }
      @media only screen and (max-width: 75em) {
        .composition__photo--p2 {
          top: -1rem;
          left: 0.25rem;
          transform: scale(1.3);
          z-index: 100; } }
    .composition__photo--p3 {
      left: 20%;
      top: 8rem; }
      @media only screen and (max-width: 75em) {
        .composition__photo--p3 {
          top: 0;
          left: 0;
          transform: scale(1.2); } }
    .composition__photo:hover {
      outline: 1rem solid #0174b2;
      transform: scale(1.05) translateY(-0.5rem);
      box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
      z-index: 20; }
  .composition:hover .composition__photo:not(:hover) {
    transform: scale(0.95); }

.reviews-card {
  margin: 0 auto;
  margin-bottom: 4rem;
  border-radius: 2rem !important;
  padding: 2rem;
  width: 50%; }
  @media only screen and (max-width: 75em) {
    .reviews-card {
      width: 60%; } }
  @media only screen and (max-width: 56.25em) {
    .reviews-card {
      width: 100%; } }
  @media only screen and (max-width: 37.5em) {
    .reviews-card {
      width: 100%; } }
  .reviews-card__carousel {
    transition: all 0.2s;
    height: 100%; }
  .reviews-card__student {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .reviews-card__student--details {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    .reviews-card__student--avatar {
      float: left;
      margin-right: 1rem; }
    .reviews-card__student--parent {
      font-family: "Baloo Tammudu 2", cursive;
      margin-bottom: 0;
      font-weight: bold;
      color: #393e46; }
    .reviews-card__student--name {
      font-family: "Baloo Tammudu 2", cursive;
      font-size: 0.9rem;
      color: #b5bac0;
      margin-bottom: 0; }
  .reviews-card__content--rating {
    display: flex;
    align-items: center;
    justify-content: center; }

.accordion__block {
  background-color: rgba(255, 255, 255, 0.4) !important; }

.accordion__summary {
  margin-bottom: 0;
  font-size: 1.5rem; }

.section-header__hero {
  margin-top: 3.5rem;
  width: 100%;
  height: calc(120vh);
  clip-path: polygon(0 0, 100% 0, 100% 95vh, 50% 100%, 0 95vh);
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(rgba(1, 116, 178, 0.6), rgba(1, 116, 178, 0.6)), url("imgs/hero-bg.jpg"); }
  .section-header__hero__text {
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
  .section-header__hero__carousel {
    margin: auto;
    max-width: 30rem;
    min-height: 15rem;
    background-color: rgba(181, 186, 192, 0.7);
    border-radius: 0.5rem;
    transition: all 0.2s;
    animation: fadeIn 1s ease-in-out 2s backwards; }
    @media only screen and (max-width: 37.5em) {
      .section-header__hero__carousel {
        margin: auto 2rem; } }

.rows {
  max-width: 72.5rem;
  margin: 4rem 0 auto; }
  .rows:not(:last-child) {
    margin-bottom: 5rem; }
  .rows:first-child {
    margin-top: 2rem; }
  .rows::after {
    content: "";
    display: table;
    clear: both; }
  .rows [class^="cols-"] {
    float: left; }
    .rows [class^="cols-"]:not(:last-child) {
      margin-right: 3.125rem; }
  .rows .cols-1-of-2 {
    width: calc((100% - 3.125rem) / 2); }
    @media only screen and (max-width: 75em) {
      .rows .cols-1-of-2 {
        width: 100%;
        margin: auto;
        max-width: 85vw; }
        .rows .cols-1-of-2:last-child {
          margin-top: 3rem; } }
  .rows .cols-1-of-3 {
    width: calc((100% - (2 *3.125rem)) / 3); }
    @media only screen and (max-width: 75em) {
      .rows .cols-1-of-3 {
        width: 40%;
        margin: 0 30%; }
        .rows .cols-1-of-3:not(:first-child) {
          margin-top: 8rem; } }
    @media only screen and (max-width: 56.25em) {
      .rows .cols-1-of-3 {
        width: 60%;
        margin: 0 20%; } }
    @media only screen and (max-width: 37.5em) {
      .rows .cols-1-of-3 {
        width: 100%;
        margin: 0 0; }
        .rows .cols-1-of-3:not(:first-child) {
          margin-top: 8rem; } }

.about {
  font-family: "Montserrat", sans-serif;
  padding: 3rem 15%;
  padding-bottom: 0;
  margin: 2rem;
  margin-bottom: 0; }
  @media only screen and (max-width: 75em) {
    .about {
      margin-bottom: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .about {
      padding: 3rem 0;
      margin-bottom: 1rem; } }

.course-plans {
  text-align: center;
  padding: 2rem;
  font-family: "Montserrat", sans-serif; }
  .course-plans .rows {
    margin: 2rem auto; }
  .course-plans--card {
    margin: 0 1rem;
    min-height: 30rem;
    border-radius: 2rem !important; }
    .course-plans--card__heading {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      box-decoration-break: clone; }
      .course-plans--card__heading > h3 {
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(to right, #03256c, #0174b2, #03256c); }
      .course-plans--card__heading.back {
        top: 10%; }
    .course-plans--card__image {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%); }
    .course-plans--card__content {
      width: 75%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -35%); }
      .course-plans--card__content.back {
        top: 42%; }
        .course-plans--card__content.back > ul {
          list-style: none;
          padding: 0; }
          .course-plans--card__content.back > ul > li {
            padding: 0.5rem; }
            .course-plans--card__content.back > ul > li:not(:last-child) {
              border-bottom: 1px solid rgba(1, 116, 178, 0.5); }
    .course-plans--card__button {
      position: absolute;
      bottom: 10%;
      right: 50%;
      width: 75%;
      transform: translateX(50%); }

.club-reviews__heading {
  width: 75%;
  margin: 0 auto; }

.review-illustration__col {
  display: flex;
  justify-content: center;
  align-items: center; }

.review-illustration__img {
  width: 100%; }

.label {
  text-align: center;
  color: #393e46;
  font-family: "Baloo Tammudu 2", cursive;
  font-weight: bold;
  text-transform: uppercase; }
  .label:first-child {
    margin-bottom: 0; }
  .label:not(:first-child) {
    margin: 1rem 0 0 0; }

.progress {
  background-color: #b5bac0 !important; }

.progress-bars {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media only screen and (max-width: 75em) {
    .progress-bars {
      margin-bottom: 4rem;
      width: 60%; } }
  @media only screen and (max-width: 56.25em) {
    .progress-bars {
      width: 100%; } }

.progress-card {
  text-align: center;
  padding: 2rem;
  border-radius: 2rem !important;
  width: 100%; }

@media (max-width: 992px) {
  .review-illustration__img {
    width: 75%; }
  .progress-bars {
    margin-top: 4rem; } }

.footer {
  background-color: rgba(1, 116, 178, 0.4);
  border-radius: 10rem 10rem 0 0;
  background-position: center;
  background-size: cover;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  @media only screen and (max-width: 37.5em) {
    .footer {
      border-radius: 5rem 5rem 0 0; } }
  .footer__content--icon-box {
    margin-bottom: 1rem; }
    .footer__content--icon-box > a:not(:last-child) {
      margin-right: 2rem; }
  .footer__content--icon {
    width: 2.5rem;
    transition: all 0.2s; }
    .footer__content--icon:hover {
      transform: scale(1.2); }
  .footer__content > p > a:hover {
    text-decoration: none; }
  .footer__content--contact {
    font-weight: bold;
    color: #0174b2;
    transition: all 0.2s; }
    .footer__content--contact--nav {
      font-weight: normal;
      color: black; }
    .footer__content--contact:hover {
      background-color: #393e46;
      color: #a6d6d6;
      padding: 5px; }

.about-page__about {
  padding-top: 6rem;
  padding-bottom: 2rem;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(rgba(1, 116, 178, 0.6), rgba(1, 116, 178, 0.6)), url("imgs/header-backdrop.jpg"); }

.about-page__content {
  text-align: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(57, 62, 70, 0.6); }
  .about-page__content--img {
    height: 15rem;
    margin-bottom: 2rem; }
  .about-page__content--text {
    text-align: left; }

.about-page .team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  transition: 0.5s; }

@media (max-width: 992px) {
  .about-page .member.ritu {
    margin-bottom: 3.5rem; } }

.about-page .team .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 50%; }

.about-page .team .member .pic img {
  transition: ease-in-out 0.3s; }

.about-page .team .member:hover {
  transform: translateY(-10px); }

.about-page .team .member .member-info {
  padding-left: 30px; }

.about-page .team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #37517e;
  font-family: "Varela Round", sans-serif; }

.about-page .team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  color: #393e46;
  font-family: "Montserrat", sans-serif; }

.about-page .team .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #cbd6e9;
  bottom: 0;
  left: 0; }

.about-page .team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif; }

.about-page .team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.about-page .team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #eff2f8; }

.about-page .team .member .social a i {
  color: #37517e;
  font-size: 16px;
  margin: 0 2px; }

.about-page .team .member .social a:hover {
  background: #47b2e4; }

.about-page .team .member .social a:hover i {
  color: #fff; }

.about-page .team .member .social a + a {
  margin-left: 8px; }

.about-page__instructors {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .about-page__instructors--founder {
    overflow: hidden;
    background-image: linear-gradient(to right bottom, #185adb, #39a2db, #185adb);
    transition: 1s;
    padding: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(57, 62, 70, 0.6);
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto; }
    .about-page__instructors--founder:hover {
      background-image: linear-gradient(to right bottom, #39a2db, #185adb, #39a2db);
      transform: scale(1.05); }
    .about-page__instructors--founder--image {
      margin-right: 1rem;
      width: 10rem;
      border-radius: 9999px;
      box-shadow: 0 2px 4px #393e46; }
    .about-page__instructors--founder--comment {
      clear: left;
      width: 75%;
      margin: auto; }

.registration {
  font-family: "Montserrat", sans-serif; }
  .registration__heading {
    margin-top: 7rem; }
  .registration__form {
    text-align: center; }
    .registration__form--item {
      width: 80%; }
      .registration__form--item--phone {
        margin-bottom: 1rem !important; }
      .registration__form--item--level {
        display: flex;
        justify-content: space-around;
        margin: 0 10% 1.5rem; }
      .registration__form--item--date {
        margin-bottom: 3rem !important; }
      .registration__form--item:not(:last-child) {
        margin-bottom: 2.25rem; }
  .registration__faq {
    width: 70%;
    margin: auto; }

@media screen and (max-width: 768px) {
  .registration__form--item--city {
    margin-bottom: 2.25rem !important; }
  .registration__form--item--date {
    margin-bottom: 4rem !important; }
  .registration__faq {
    width: 100%; } }
